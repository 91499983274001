import { Container, Row, Col } from 'react-bootstrap';
import React from 'react';
import PageTitle from '../../components/PageTitle';
import NewClassroomBlock from '../Home/components/NewClassroomBlock';
import Features from '../../components/Features';
import { isMobile } from '../../utils/isMobil';
import Sliders from '../../components/Sliders';
import CategoryList from '../Home/components/CategoryList';
import { Link } from 'react-router-dom';
import TeacherComponent from '../Home/components/TeacherComponent';
function Business() {
  const contactUs = () => {
    window.open('https://0u0c51hhbc2.typeform.com/wisenrise', '_blank');
  };
  return (
    <div>
      <div>
        <Row>
          <Col sm="12">
            <PageTitle title={'Wise&Rise İş Dünyasında!'} subTitile={'Ekibinizin yeteneklerini üst düzey eğitimlerle geliştirin.'} />
          </Col>
        </Row>
      </div>

      <div>
        <Container className="position-relative h-100 mobil-max-width-container my-5">
          <div className="slider-inner h-100">
            <Row className="my-3">
              <Col sm="12" md="8" className="mt-2">
                <div className="mobil-content">
                  <h4 className="title mobil-title">
                    Alanının en iyilerinden öğrenin,
                    <br /> iş dünyasında öne çıkın.
                  </h4>
                  <h4 className="title mobil-sub-title">Wise&Rise ile {isMobile() ? <br /> : ''} ekibinizi geleceğe taşıyın!</h4>
                </div>

                <div className="d-flex flex-wrap align-items-center"></div>
              </Col>
              <Col sm="12" md="4" className="mt-2">
                <div className="w-100 h-100 d-flex justify-content-center align-items-center mt-0 mt-xs-1 mobil-btn-wrapper">
                  <button type="submit" className="btn btn-hover btn-primary1  w-10" onClick={contactUs}>
                    Bize Ulaşın
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="my-5">
        <NewClassroomBlock />
      </Container>
      <section id="iq-favorites">
        <Sliders headerTitle={'En Çok İzlenenler'} prev="prevTrending" next="nextTrending" type="discover" />
      </section>
      <section>
        <div className="mt-1" style={{ minHeight: '435px' }}>
          <Container className="mt-3">
            <Row className="align-items-center">
              <Col sm="12" fluid>
                <div className="text-center iq-breadcrumb-two">
                  <h6 className=" text-primary mb-3">KATEGORİLER</h6>
                  <h3 className="title mb-4 mb-md-5">Tüm Eğitimler</h3>
                  <div className="">
                    <p className={`mb-4 m-md-auto ${isMobile() ? 'mobil-sub-title' : ''} `} style={{ maxWidth: '660px' }}>
                      Her ay yeni isimler ve yeni içeriklerle sizi ilerlemeye davet ediyoruz!
                    </p>
                    <p className={`mb-0 m-md-auto ${isMobile() ? 'mobil-title' : ''}`} style={{ maxWidth: '660px' }}>
                      9 farklı kategoride gelişmek istediğiniz alanı seçin, eğitiminizi tamamlayın!
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <CategoryList />
          </Container>
        </div>
      </section>
      <section className="py-3 d-none d-sm-block">
        <div className="mt-5 mb-5">
          <Container>
            <Row>
              <Col sm="12" md="12" lg="6">
                <TeacherComponent business={true} />
              </Col>
              <Col sm="12" md="12" lg="6">
                <div className="d-flex flex-wrap align-items-center">
                  <div
                    className="d-flex align-items-center mt-2 mt-md-3"
                    data-iq-gsap="onStart"
                    data-iq-position-x="-200"
                    data-iq-delay="-0.5"
                    style={{ width: '271px', height: '34px' }}
                  >
                    <span className="fs-2 main-title channel-logo text-uppercase text-primary">EĞİTİMLER</span>
                  </div>
                </div>
                <h4
                  className=" big-title title text-uppercase mt-4 mb-4"
                  data-iq-gsap="onStart"
                  data-iq-position-x="-200"
                  style={{ lineHeight: '40px' }}
                >
                  İş dünyasında fark yaratmak için ilginizi çeken kategorileri seçin.
                </h4>
                <div className="d-flex flex-wrap align-items-center text-left">
                  <p data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
                    Türkiye’nin en iyileriyle zirveye ulaşın.
                  </p>
                </div>
                <div className="d-flex align-items-center r-mb-23  mt-3" data-iq-gsap="onStart" data-iq-position-y="80" data-iq-delay="0.8">
                  <Link to="/kategoriler" className="btn btn-outline-light">
                    Tümünü Gör
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <div>
        <Features />
      </div>
      <div>
        <Container className="position-relative h-100 mobil-max-width-container my-5">
          <div className="slider-inner h-100">
            <Row className="my-3">
              <Col sm="12" md="8" className="mt-2">
                <div className="mobil-content">
                  <div className="">
                    <h3 className="my-1">Wise&Rise İş Dünyasında!</h3>
                    <p>
                      Wise&Rise ile kurumunuzda bir öğrenme kültürü oluşturun. Grup indirimleri ve daha fazla bilgi için bizimle iletişime geçin.{' '}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-wrap align-items-center"></div>
              </Col>
              <Col sm="12" md="4" className="mt-2">
                <div className="w-100 h-100 d-flex justify-content-center align-items-center mt-0 mt-xs-1 mobil-btn-wrapper">
                  <button type="submit" className="btn btn-hover btn-primary1  w-10" onClick={contactUs}>
                    Bize Ulaşın
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}
export default Business;
