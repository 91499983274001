import React, { useState } from 'react';
import { Row, Col, Nav, Tab, Modal } from 'react-bootstrap';

import user from '../../../assets/images/user/user.jpg';
import addIcon from '../../../assets/icons/icons-plus-bg.png';

import { useApi } from '../../../contexts/RequestContext';
import { CreateNote, UpdateNote } from '../../../Services/Note';
import TeacherModal from '../../../components/TeacherModal';

import NoteItem from './NoteItem';
import './style.css';
import style from './style.module.css';

export default function NotesBlock({ item, time, videoHandler }) {
  const { teacher, Refresh } = useApi();
  const [more, setMore] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [createNote, setCreateNote] = useState(false);
  const [updateNote, setUpdateNode] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const sendNote = async () => {
    let body = {};
    if (!updateNote) {
      body = {
        video_id: item.id,
        duration: time,
        content: newNote,
      };
      CreateNote(body)
        .then((res) => {
          Refresh();
          setCreateNote(false);
          videoHandler('play');
          setNewNote('');
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      body = {
        content: newNote,
      };

      UpdateNote(noteId, body)
        .then((res) => {
          Refresh();
          setCreateNote(false);
          videoHandler('play');
          setNewNote('');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      {console.log(teacher)}
      <Row>
        <Col md="3" xs="2" className=" text-xs-start">
          <div onClick={handleShow1} className="upload_profile d-inline-block text-xs-start cursor-pointer">
            <div
              // src={teacher?.photo ? teacher?.photo?.path : user}
              style={{
                background: `url(${teacher?.photo ? teacher?.photo?.path : user})`,
                backgroundSize: 'cover',
              }}
              className="profile-pic avatar-50 rounded-circle img-fluid"
            />
            {/* <div className="p-image">
                                                {/* <i className="ri-pencil-line upload-button"></i> */}
            {/* <input className="file-upload" type="file" accept="image/*"/>
                                            </div> */}
          </div>
        </Col>
        <Col xs="10" md="9" className="">
          <div className="text-left w-100">
            <h6 className="cursor-pointer" onClick={handleShow1}>
              {' '}
              {teacher?.name}
            </h6>
            <div className="d-flex pt-2">
              {teacher?.categories?.map((item) => (
                <span className={`${style.category} mr-2`}>{item.name}</span>
              ))}
            </div>

            {/* <p className="text-mudet mt-1" style={{ fontSize: '14px' }}>
              {' '}
              {teacher?.categories ? teacher?.categories[0]?.name : ''}
            </p> */}
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="position-relative">
            <Tab.Container defaultActiveKey="trending-data1" className="trending-custom-tab">
              <div className="tab-title-info position-relative">
                <Nav
                  as="ul"
                  variant="pills"
                  className="trending-pills trending-pills-watch nav-pills d-flex justify-content-start align-items-center flex-nowrap text-left "
                >
                  <Nav.Item as="li" className="nav-item">
                    <Nav.Link href="" eventKey="trending-data1">
                      Ders İçeriği
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="nav-item">
                    <Nav.Link href="" eventKey="trending-data2">
                      Not Al
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className="ws-line"></div>
              </div>
              <Tab.Content className="trending-content mt-1 mt-md-4">
                <Tab.Pane eventKey="trending-data1" className=" show">
                  <div className="trending-info w-100 animated fadeInUp text-left">
                    {item?.description}

                    {/* <div className="trending-list mt-4">
                      <div className="text-primary title">
                        Eğitmen
                        <span className="text-body">
                          Wagner Moura, Boyd Holbrook, Joanna
                        </span>
                      </div>
                      <div className="text-primary title">
                        Genres:
                        <span className="text-body">
                          Crime, Action, Thriller, Biography
                        </span>
                      </div>
                      <div className="text-primary title">
                        This Is:
                        <span className="text-body">Violent, Forceful</span>
                      </div>
                    </div> */}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="trending-data2" className=" fade show " id="trending-episode1">
                  <div className="align-items-start w-100 animated fadeInUp mt-2">
                    <div
                      className={`${style.addCard}`}
                      onClick={() => {
                        setCreateNote(true);
                        videoHandler('pause');
                        setUpdateNode(false);
                      }}
                    >
                      <div className={style.createText}>Ders Notu Oluştur {time}</div>
                      {!createNote && <img src={addIcon} />}
                    </div>
                    {createNote && (
                      <div className={`${style.addCard} d-flex flex-column mt-3`}>
                        <textarea
                          type="text"
                          name="description"
                          className={`${style.addCard44}`}
                          id="exampleInputEmail1"
                          placeholder="Not almaya başla..."
                          autoComplete="off"
                          rows={1}
                          onChange={(e) => {
                            setNewNote(e.target.value);
                          }}
                          required
                          style={{ lineHeight: '25px', borderRadius: '10px' }}
                          defaultValue={newNote}
                        />
                        <div className="d-flex justify-content-end w-100 mt-3">
                          <div
                            className="btn btn-link"
                            onClick={() => {
                              setCreateNote(false);
                              videoHandler('play');
                            }}
                          >
                            İptal
                          </div>
                          <button disabled={!newNote} type="submit" className="btn btn-hover  " style={{ borderRadius: '10px' }} onClick={sendNote}>
                            Kaydet
                          </button>
                        </div>
                      </div>
                    )}

                    <NoteItem item={item} setCreateNote={setCreateNote} setUpdateNode={setUpdateNode} setNewNote={setNewNote} setNoteId={setNoteId} />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Col>
      </Row>
      <Modal size="lg" show={show1} onHide={handleClose1} centered>
        <Modal.Body style={{ background: '#14161A', overflow: 'hidden' }}>
          <TeacherModal item={teacher} handleClose={handleClose1} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
