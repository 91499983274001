import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getPlans } from '../../Services/AuthApi';
import { useAuth } from '../../contexts/AuthContext';

export default function PreInformationForm() {
  const [price, setPrice] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(false);
  useEffect(() => {
    getPlans('Yearly')
      .then((res) => {
        setPrice(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setSelectedPlan(localStorage.getItem('selectedPlan'));
    return () => {};
  }, []);
  return (
    <div>
      <div className=" " style={{ paddingTop: '80px', paddingBottom: '5px' }}>
        <Container style={{ background: '#14161a' }} fluid>
          <Container>
            <Row className="align-items-left">
              <Col sm="12">
                <nav aria-label="breadcrumb" className="text-left iq-breadcrumb-two">
                  <h1 className="h2 title">Ön Bilgilendirme Formu</h1>
                  <span>
                    <Link to="/">Anasayfa</Link> /<span className="text-primary">Ön Bilgilendirme Formu</span>
                  </span>
                </nav>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
      <main id="main" className="site-main">
        <Container>
          <Row>
            <Col lg="12" sm="12">
              <div className="text-left">
                <div className="mb-1">
                  <p className="my-3">
                    Okumakta olduğunuz ön bilgilendirme formu akdetmek üzere olduğunuz Üyelik ve Çevrimiçi (Online) Abonelik Hizmeti Satın Alımına
                    İlişkin Mesafeli Sözleşme (“Mesafeli Sözleşme”) ile ilgili bilgilendirilmeniz amacıyla hazırlanmıştır.
                  </p>
                </div>
                <div className="mb-3">
                  <p className="mb-2">
                    Bu form, 6502 sayılı Tüketicinin Korunması Hakkında Kanun, Mesafeli Sözleşmelere Dair Yönetmelik ve Abonelik Sözleşmeleri
                    Yönetmeliğine uygun şekilde hazırlamıştır.
                  </p>
                </div>
                <div className="mb-3">
                  <p className="mb-0">
                    Lütfen bu formun, Kullanım Koşulları’nın ve onaylayacağınız Mesafeli Sözleşme’nin tüm hükümlerini okuyup anladığınızdan emin
                    olunuz. Bunun için yeterli süreniz bulunmaktadır.
                  </p>
                </div>
                <div className="mb-5">
                  <p className="my-3">
                    Okumakta olduğunuz ön bilgilendirme formu akdetmek üzere olduğunuz Üyelik ve Çevrimiçi (Online) Abonelik Hizmeti Satın Alımına
                    İlişkin Mesafeli Sözleşme (“Mesafeli Sözleşme”) ile ilgili bilgilendirilmeniz amacıyla hazırlanmıştır.
                  </p>
                </div>
                <table class="table table-bordered text-center ">
                  <thead>
                    <tr>
                      <th className="w-25 text-center ">
                        {' '}
                        <input type="checkbox" disabled checked={selectedPlan} />
                      </th>
                      <th className="w-25 text-center">
                        {' '}
                        <input type="checkbox" disabled checked={!selectedPlan} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="text-center">Peşin</th>
                      <td className="text-center">Taksit</td>
                    </tr>
                    <tr>
                      <td> 1 Hesap 4 Profil</td>
                      <td> 1 Hesap 4 Profil</td>
                    </tr>
                    <tr>
                      <td> {price[3]?.name}</td>
                      <td> {price[2]?.name}</td>
                    </tr>

                    <tr>
                      <td>
                        {' '}
                        <ul className="mx-5">
                          <li>9 Kategori+20’den fazla içerik</li>
                          <li>Her Ay Yeni İçerikler</li>
                          <li>Bilgisayardan, Mobil Cihazdan, Tabletten Sınırsız Erişim</li>
                          <li>İzlerken Not Alma Özelliği</li>
                          <li>Mobil Cihazdan Podcast Dinleme Özelliği</li>
                          <li>Her Eğitimle Yeni Bir Sertifika Kazanma İmkânı</li>
                          <li>İstediğin Zaman İptal Etme Seçeneği</li>
                        </ul>
                      </td>
                      <td>
                        <ul className="mx-5">
                          <li>9 Kategori+20’den fazla içerik</li>
                          <li>Her Ay Yeni İçerikler</li>
                          <li>Bilgisayardan, Mobil Cihazdan, Tabletten Sınırsız Erişim</li>
                          <li>İzlerken Not Alma Özelliği</li>
                          <li>Mobil Cihazdan Podcast Dinleme Özelliği</li>
                          <li>Her Eğitimle Yeni Bir Sertifika Kazanma İmkânı</li>
                          <li>İstediğin Zaman İptal Etme Seçeneği</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="mb-3 mt-5">
                  <p>
                    Wise&Rise hizmeti, Mesafeli Sözleşmeler Yönetmeliği’nin 15. (on beşinci) maddesinin ‘‘ğ’’ bendinde yer alan ‘‘Elektronik ortamda
                    anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayri maddi mallara ilişkin sözleşmeler’’ kapsamında olup mevzuat
                    gereği Wise&Rise’a üye olup dijital içeriklere eriştiğiniz andan itibaren cayma hakkınız bulunmamaktadır. Lütfen bu hususa dikkat
                    ediniz.
                  </p>
                </div>
                <div className="mb-3">
                  <p>
                    Ödemenizi banka, kredi kartı, ön ödemeli kart ile Wise&Rise’da yer alan ödeme bölümünden Iyzico altyapısı ile güvenli şekilde
                    gerçekleştirilebilirsiniz. Türkiye dışından oluşturulacak üyeliklerde Mastercard ya da VISA altyapısı kullanan herhangi bir kredi
                    kartıyla ödeme gerçekleştirilmesi mümkündür.
                  </p>
                </div>
                <div className="mb-3">
                  <p>
                    Ödeme tuşuna basarak seçtiğiniz plana dair belirtilen vergiler dâhil satış fiyatını ödeme yükümlülüğü altına gireceğinizi
                    hatırlatırız.
                  </p>
                </div>
                <div className="mb-3">
                  <p>Üyelik süreçlerine dair hukuki metinleriniz bildirdiğiniz e-posta adresine gönderilecektir.</p>
                </div>
                <div className="mb-3">
                  <p>
                    Wise&Rise’a herhangi bir erişim kısıtlaması olmadan kullanabilirsiniz. Bulunduğunuz ülke kaynaklı erişim sıkıntılarından sorumlu
                    olamayacağımızı bilmenizi isteriz.
                  </p>
                </div>

                <div className="mt-5">
                  <span className="h6 d-block text-center">Saygılarımızla,</span>
                  <h5 className="h4 text-center">Veranova Medya ve Dijital Yayın Hizmetleri Anonim Şirketi </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
