import React, { useState } from 'react';
import htm12canvas from 'html2canvas';
import jsPDF from 'jspdf';
import closeIcon from '../../assets/icons/x.png';
import style from './style.module.css';
import CertificateItem from './Certificate';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { getUsersCertificate } from '../../Services/User';
import { toast } from 'react-toastify';
import { Row, Col, Modal } from 'react-bootstrap';

const CertificateModal = ({ teacher, item, handleClose }) => {
  const [loader, setLoader] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState('');
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShow = () => {
    getUsersCertificate(item?.id).then((res) => {
      console.log(res.data);
      setCertificateUrl(res?.data?.image);
    });
    setShow(true);
  };
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const downloadPDF = () => {
    const capture = document.querySelector('.certificate_v1');
    setLoader(true);
    htm12canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL('img/png');
      const doc = new jsPDF('l', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save(`Wisenrise_${teacher?.name}.pdf`);
    });
  };
  const handleShare = (id) => {
    // Prepare share data
    getUsersCertificate(item?.id).then((res) => {
      setCertificateUrl(res?.data?.image);
    });
    let shareUrl = '';
    if (id == 1) {
      shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(certificateUrl)}`;
    } else if (id == 2) {
      shareUrl = `https://wa.me/?text=${encodeURIComponent(certificateUrl)}`;
    } else if (id == 3) {
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(certificateUrl)}`;
    } else {
      shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(certificateUrl)}`;
    }
    window.open(shareUrl, '_blank');
  };
  const notify = async () => {
    await navigator.clipboard.writeText(certificateUrl);
    toast.success('Başarıyla kopyalandı!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
    // handleClose()
  };
  return (
    <>
      <div className={`p-3 ${style.modal}`}>
        <div className={`d-flex justify-content-between align-items-start ${style.header}`}>
          <div className="d-flex flex-wrap flex-sm-nowrap  justify-content-center justify-content-sm-start " style={{ width: '90%' }}></div>
          <div className="" style={{ position: 'absolute', top: '0', right: '5px' }}>
            <img onClick={handleClose} width="30" src={closeIcon} alt="" />
          </div>
        </div>
        <CertificateItem item={item} />
        <div
          className="d-flex align-items-center r-mb-23 justify-content-end mt-4"
          data-iq-gsap="onStart"
          data-iq-position-y="80"
          data-iq-delay="0.8"
        >
          <div onClick={downloadPDF} className="btn btn-link btn-outline-light mr-3">
            PDF indir
          </div>
          <div className="btn btn-link btn-outline-light">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="">
              <DropdownToggle className="cursor-pointer text-white" tag="div">
                Paylaş
              </DropdownToggle>
              <DropdownMenu className="bg-dark ">
                <DropdownItem className=" text-white mr-1" onClick={() => handleShare(1)}>
                  <i className="fa fa-linkedin mr-2"></i>Linkedin
                </DropdownItem>
                <DropdownItem className="text-white h2" onClick={() => handleShare(2)}>
                  <i className="fa fa-whatsapp mr-1"></i> Whatsapp
                </DropdownItem>
                <DropdownItem className="text-white h2" onClick={() => handleShare(3)}>
                  <i className="fa fa-facebook mr-2"></i> Facebook
                </DropdownItem>
                <DropdownItem className="text-white h2" onClick={() => handleShare(4)}>
                  {' '}
                  <i className="fa fa-twitter mr-1"></i> Twitter
                </DropdownItem>

                <DropdownItem className="text-white h2" onClick={handleShow}>
                  <i className="fa fa-copy mr-1"></i> Kopyala
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
      <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered style={{ maxWidth: '500px', margin: '0 auto' }}>
        <Modal.Body style={{ background: '#272C32', textAlign: 'center' }}>
          <Row className="m-profile" style={{ background: 'transparent', padding: '20px' }}>
            <Col>
              <div className="d-flex justify-content-end m-0 p-0">
                <div className="p-0" onClick={handleCloseModal} style={{ cursor: 'pointer', zIndex: '999' }}>
                  <img width={'30'} src={closeIcon} alt="" />
                </div>
              </div>
              <div className="we-header-title-modal text-center">
                <h5 className="text-primary p-1">Paylaş</h5>
                <span>Sertifikanı görmesini istediğin kişilerle paylaşabilirsin</span>
              </div>
              <div className="path mt-3">{certificateUrl}</div>
            </Col>
          </Row>
          <div onClick={notify} className="btn btn-hover btn-copy ">
            Kopyala
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CertificateModal;
