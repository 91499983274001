import axios from 'axios';
const API = process.env.REACT_APP_BASE_ENDPOIND;
const register = `${API}register`;

export const Auth = async (body) => {
  const { data } = await axios.post(`${API}auth`, body);
  return data;
};
export const Reg1 = async (body) => {
  const { data } = await axios.post(`${register}/1/steps`, body);

  return data;
};
export const Reg2 = async (id, body) => {
  const { data } = await axios.post(`${register}/2/steps/${id}`, body);
  return data;
};
export const Reg3 = async (id, body) => {
  const { data } = await axios.post(`${register}/3/steps/${id}`, body);
  return data;
};
export const Reg4 = async (id, body) => {
  const { data } = await axios.post(`${register}/4/steps/${id}`, body);
  return data;
};

export const getPlans = async (period) => {
  const { data } = await axios.get(`${API}plans?period=${period}`);
  return data;
};

export const getOrders = async () => {
  const { data } = await axios.get(`${API}orders`);
  return data;
};

export const cancelAccount = async () => {
  const { data } = await axios.put(`${API}disable_account`, {});
  return data;
};

export const activateAccount = async (body) => {
  const { data } = await axios.post(`${API}enable_account`, body);
  return data;
};
